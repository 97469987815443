.device_test {
    h2 {
        text-align: left;
    }
    .device_test_box {
        padding: 24px;
        margin: 24px 0;
        text-align: left;
        border-radius: 8px;
        background-color: #273247; 
        .camera_box {
            width: 100%;
            height: 200px;
            border-radius: 8px;
            background-color: #121721;
        }
        .fps_test {
            margin-top: 24px;
            display: block;
        }
        .device_test_list_box {
            border-radius: 8px;
            margin-top: 16px;
            padding: 16px;
            position: relative;
            cursor: pointer;
            background-color: #2F3A50;
            .device_test_header {
                display: flex;
                align-items: center;
                .device_icon {
                    width: 50px;
                    height: 50px;
                    line-height: 56px;
                    border-radius: 8px;
                    text-align: center;
                    margin-right: 8px;
                    background-color: #1A253B;
                }
                .form-select {
                    width: 80%;
                    background-color: transparent;
                    border: none;
                    color: #fff;
                    outline: none;
                    height: 40px;
                    cursor: pointer;
                    option {
                        color: #000;
                    }
                }
            }
        }
    }
    .contact_support {
        margin-top: 20px;
        a {
            color: #2BA8E0;
        }
    }
}

@media only screen and (max-width: 992px) {
    .shoutout_content_box .device_test {
        max-width: 460px;
        margin: 0 auto;
    }
    .device_test .device_test_box {
        margin-top: 16px;
    }
}
