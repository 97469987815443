.thankyou_form_box {
    border-radius: 8px;
    overflow: hidden;
    margin-top: 40px;
    background-color: #2F3A50;
    .thankyou_header {
        padding: 20px 10px;
        background-color: #273247;
        p {
            margin: 0;
        }
    }
    .thankyou_content {
        padding: 20px;
        form {
            padding-top: 16px;
        }
    }
}
.shoutout_thanks_wrap {
    h1 {
        padding: 15px 0;
    }
    > svg {
        width: 100px;
        path {
            fill: #24943D;
        }
    }
}
// .shoutout_thanks {
//     .shoutout_content_section {
//         min-height: 100vh;
//     }
//     .shoutout_content_box {
//         height: 495px;
//     }
// }
.shoutout_thanks_wrap {
    lottie-player {
        width: 200px;
        height: 200px;
        margin: 0 auto 20px auto;    
    }
    .line-seperator {
        width: 140px;
        height: 2px;
        background-color: #737373;
        display: block;
        margin: 20px auto 15px auto;
    }
    p {
        line-height: 1.4;
        padding: 0;
        font-weight: 500;
        margin: 0;
        .text-red {
            color: #ff0000;
            text-shadow: 1px 1px 1px #fff;
        }
    }
    .social_icons {
        ul {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            margin: 15px auto;
            li {
                width: 34px;
                margin: 0 5px;
                a {
                    display: block;
                    svg {
                        fill: #fff;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .shoutout_thanks_wrap {
        >div[role="button"] {
            width: 130px !important;
            height: 130px !important;
        }
    }
}