

//iphone adress bar 100vh solution
//https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
.shoutout_invitation, 
.shoutout_getStart {
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
} 

.shoutout_getStart_content {
    h6 {
        // color: #808080;
        font-weight: 600;
        padding-bottom: 10px;
        margin: 0;
    }
    // .btn {
    //     margin-top: 20px;
    // }
    .play_btn {
        display: none;
        margin-bottom: 20px;
    }
}

.shoutout_respond_btn {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;

    input[type="radio"] {
        position: relative;
        appearance: none;
        -webkit-appearance: none;
        transition: linear 0.8s;
        height: 0;
        width: 0;
        -webkit-tap-highlight-color: transparent;
    }
    label {
        padding: 0;
        height: 100px;
        width: 100px;
        line-height: 1.3px;
        text-align: center;
        margin-right: 3px;
        position: relative;
        border-radius: 50%;
        transition: all 0.2s ease-out;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: rgb(47 58 80 / 40%);
        svg {
            path {
                fill: #C8C8C8;
            }
        }
        span {
            display: block;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            margin-top: 8px;
            color: #C8C8C8;
        }
    }
    input[type="radio"]:checked + label {
        background-color: #2BA8E0;
    }
    input[type="radio"]:checked + label {
        svg {
            path {
                fill: #fff;
            }
        }
        span {
            color: #fff;
        }
    }
}

.shoutout_legal_part {
    text-align: left;
    padding-top: 40px;
    .form-check {
        margin-top: 16px;
        padding-left: 0;
        .form-check-input {
            // margin-top: 2px;
            display:none !important
        }
    }
    input[type=checkbox] + label{
        font-weight:normal;
        cursor:pointer;
        margin-bottom:0;
        padding-left: 24px; 
        margin:0;
        font-size: 14px;
        line-height:1.3;
        color: #fff;
        position: relative;
        display: inline-block;
        &:before {
            content:"";
            display:inline-block;
            width:17px;
            height:17px;
            vertical-align:middle;
            margin-right:8px;
            background-color:#fff;
            border:1px solid #ccc;
            border-radius:2px;
            font-weight:normal;
            position: absolute;
            left: 0;
        }
        span {
            color: #F39C12;
        }
    }
    input[type=checkbox]:checked + label {
        &:before {
            content:"✔";
            color:#1A253B;
            background-color:#fff;
            border:1px solid #fff;
            text-align:center;
            line-height:14px;
        }
    }
}

.continue_btn_wrap {
    padding-top: 48px;
    .btn {
        border-radius: 36px;
        font-size: 16px;
        font-weight: 600;
    }
}


@media screen and (max-width: 992px) {
    .shoutout_getStart_content {
        color: #fff;
        max-width: 600px;
        margin: 0 auto;
        h6 {
            color: #fff;
        }
        .play_btn {
            display: inline-block;
        }
        // .question_box {
        //     .question_listing {
        //         justify-content: center;
        //     }
        // }
        .shoutout_exprience_btnGroup {
            h5 {
                width: 100%;
            }
        }
    }
    
}
@media screen and (max-width: 680px) {
    .shoutout_getStart_content {
        .shoutout_exprience_btnGroup  {
            h5 {
                font-size: 18px;
            }
        }
    }
    .shoutout_legal_part {
        h4 {
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 480px) {
    .shoutout_legal_part {
        padding-top: 24px;
    }
    .shoutout_getStart_content {
        .shoutout_exprience_btnGroup  {
            h5 {
                font-size: 16px;
            }
        }
        .shoutout_legal_part {
            h4 {
                font-size: 16px;
            }
        }
    }
    .shoutout_respond_btn {
        label {
            width: 76px;
            height: 76px;
            svg {
                height: 24px;
            }
            span {
                margin-top: 0;
                font-size: 12px;
            }
        }
    }
    .shoutout_legal_part {
        .form-check {
            padding-left: 0;
        }
        input[type=checkbox] + label {
            font-size: 12px;
        }
    }
}