.shoutout_content_submit {
    text-align: left;
    padding-top: 48px;
    .form-group {
        margin-bottom: 2rem;
    }
    .shoutout_submit_content {
        margin-bottom: 2rem;
    }
    .progressBar {
        margin-top: 24px;
    }
    .shoutout_progress_wraps {
        h2 {
            color: #FFB90A;
            font-size: 32px;
            line-height: 40px;
        }
        h3 {
            font-size: 18px;
            font-weight: 500;
        }
    }
}
.shoutout_submit_wrap {
    max-width: 550px;
    margin: 0 auto;
    padding-bottom: 30px;
    // h3 {
    //     margin-bottom: 30px;
    // }
    // h5 {
    //     margin-bottom: 10px;
    // }
    // p {
    //     margin-bottom: 20px;
    // }
    ul {
        margin-bottom: 20px;
        li {
            position: relative;
            padding: 0 0 5px 12px;
            &:before {
                content: "";
                width: 6px;
                height: 6px;
                background-color: #757575;
                position: absolute;
                left: 0;
                top: 6px;
                border-radius: 50%;
            }
        }
    }
}
.shoutout_submit_form {
    max-width: 420px;
    // padding: 30px 0;
}

.shoutout_progress_wraps {
    max-width: 380px;
    text-align: center;
    position: absolute;
    width: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 520px;
    padding: 0 30px;
    .wait_emoji {
        font-size: 64px;
        margin-bottom: 26px;
        display: block;
    }
    h5 {
        margin: 0;
        padding-bottom: 12px;
        font-size: 14px;
    }
    .progress {
        border-radius: 14px;
        height: 20px;
        font-size: 10px;
        .progress-bar {
            background-color: #28a745;
        }
    }
    svg {
        g {
            path {
                fill: #2BA8E0;
            }
        }
    }
}

@media screen and (max-width: 680px) {
    .shoutout_content_submit {
        padding-top: 16px;
    }
    .shoutout_content_submit .shoutout_submit_content {
        margin-bottom: 1.5rem;
    }
    .shoutout_submit_wrap {
        padding-bottom: 0;  
    }
    .shoutout_submit_wrap .checkbox {
        margin: 24px 0 !important;
    }
}

@media screen and (max-width: 480px) {
    .shoutout_content_submit .shoutout_progress_wraps h2 {
        font-size: 28px !important;
        line-height: 34px  !important;
    }
    .shoutout_content_submit .shoutout_progress_wraps h3 {
        font-size: 16px;
    }
}