.menu {
    background-color: #2F3A50;
    width: 350px;
    height: 100vh;
    position: fixed;
    left: -360px;
    transition: all 0.2s ease-out; 
    padding: 24px;
    overflow-y: scroll;
    .btn {
      color: white;
    }
    ul {
      margin-top: 0;
      padding-top: 0;
      padding: 0;
      li {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        line-height: 44px;
        padding: 6px 0px;
        cursor:pointer;
        a {
            display: flex;
            align-items: center;
            svg {
                margin-right: 10px;
            }
        }
      }
    }
    &.open {
        left: 0 !important;
        z-index: 99;
    }
  }

  
  .menu-click {
    cursor:pointer;
    display: inline-block;
    margin-bottom: 32px;
    svg {
      cursor:pointer;
      height: 16px;
    }
  }
    .menu_heading {
        margin-bottom: 24px;
    }
      
  .content {
    position: relative;
    .menu-click {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9;
    }  
  }
  .bottom_link {
    padding-top: 20px;
    border-top: 1px solid rgb(255 255 255 / 20%);
    margin-top: 20px;
    a {
        display: flex;
        align-items: center;
        svg {
            margin-right: 10px;
        }
    }
  }
  
  .link {
    position: absolute;
    bottom: 15px;
    right: 15px;
    a {
      color: #000;
      text-decoration: none;
    }
    a:hover{
      text-decoration: underline;
    }
  }
  

  @media only screen and (max-width: 992px) {
    .content .menu-click {
        padding-top: 10px;
        left: auto;
        right: 14px;
    }
    .menu {
      left: auto;
      right: -360px;
      padding: 48px 24px 24px;
    }
    .menu.open {
      left: auto !important;
      z-index: 999;
      right: 0 !important;
    }
    .menu-click {
      position: absolute;
      right: 16px;
      top: 20px;
    }
  }

  @media only screen and (max-width: 690px) {
    .content .menu-click {
        padding: 10px;
    }
  }