/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* 
.stori_frame_slider > div > button {
  display:none;
} */

/* .shoutout_look_good_action {
  bottom: 0px;
} */

.tex-danger {
  color: #ff4433 !important;
  font-weight: 600;
}

.input-unset {
  border-color: unset !important;
}

.input-danger {
  border-color: #ff4433 !important;
}
