.decsion_box {
    .shoutout_content_box {
        max-width: 600px;
    }
}
.shoutout_content_section.uploading_process {
    .shoutout_decision_wrapper  {
        display: none;
    }
    .shoutout_look_good_action {
        display: none;
    }
}
.shoutout_look_good_action {
    max-width: 420px;
    margin: auto;
    text-align: center;
    padding: 20px 0 0 0;
    
    .shoutout_look_good_action_btnGroup {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .shoutout_look_good_action_btn {
            width: 120px;
            padding: 10px;
        }
        .btn  {
            width: 100px;
            height: 100px;
            padding: 0;
            border-radius: 50%;
            text-align: center;
            transition: all 0.2s ease-out;
            line-height: 1.3;
            span {
                font-size: 44px;
            }
            small {
                display: block;
                font-size: 100%;
            }
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

.shoutout_camra_video_wrap {
    padding: 20px 0;
}
.shoutout_look_good {
    align-items: center;
    min-height: 100vh;
    
    .shoutout_content_section {
        padding: 15px 15px 0 15px;
    }
}
.shoutout_decision_wrapper {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    // max-width: 400px;
    margin: auto;
    
    
    &:before {
        display: block;
        content: "";
        padding-top: 133%;
    }


    .frame {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        z-index:1;
    }

    .media {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        object-fit: cover;
        z-index:0;
    }
    .play_btn {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        margin: auto;
        z-index: 1;
        border: 2px solid #fff !important;
        background-color: transparent;
        &:hover {
            background-color: #fff;
        }
    }
}

// terms popup style
.term_popup {
	display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999999;
    top: 0;
    left: 0;

    .term_popup__content {
        position: absolute;
        width: 100%;
        max-width: 1000px;
        top: 6%;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 8px;
        padding: 0 24px;

        .term_popup_box {
            overflow-y: scroll;
            overflow-x: hidden !important;
            padding: 24px;
            border-radius: 8px;
            background-color: #F2FCFC;
            max-height: 100%;
            color: #000;
            height: calc(100vh - 80px);

            .herader_txt {
                text-align: center;
                margin-bottom: 32px;
                h3 {
                    font-size: 28px;
                    line-height: 34px;
                }
            }
            .content_txt{
                h5 {
                    font-size: 20px;
                    line-height: 34px;
                    padding-top: 5px;
                }
                p {
                    padding-top: 15px;
                }
            }
        }
        .term_popup__content_close {
            position: absolute;
            right: 8px;
            top: -16px;
            width: 32px;
            height: 32px;
            color: #fff;
            text-align: center;
            font-size: 28px;
            line-height: 26px;
            cursor: pointer;
            border-radius: 100%;
            background-color: #289cd7;
            box-shadow: 4px 4px 16px rgb(0 0 0 / 24%);
            display: inline-block;
        }
    }
}










//mantain square ratio
.shoutout_decision_wrapper.text_option {
    &:before {
        padding-top: 100%;
    }
}
@media screen and (max-width: 680px) {
    .shoutout_look_good {
        align-items: start;
    }
    .shoutout_decision_wrapper:before {
        padding-top: 100%;
    }
/*     .shoutout_decision_wrapper:before {
        padding-top: 124%;
    } */
    .shoutout_camra_video_wrap {
        padding: 10px;
    }
    .media_mobile {
        &.shoutout_decision_wrapper:before {
            padding-top: 160%;
        }
    }
    .shoutout_look_good_action .shoutout_look_good_action_btnGroup .btn {
        width: 80px;
        height: 80px;
    }
    .shoutout_look_good_action .shoutout_look_good_action_btnGroup .btn span {
        font-size: 28px;
    }
    .shoutout_look_good_action .shoutout_look_good_action_btnGroup .btn small {
        font-size: 14px;
    }
}

@media screen and (max-width: 450px) {
    .shoutout_decision_wrapper:before {
        padding-top: 140%;
    } 
}
@media screen and (max-width: 380px) {
    
}