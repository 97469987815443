// Popup
.uploading_popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999999;
    top: 0;
    left: 0;
}
.uploading_popup .uploading_popup__content {
    position: absolute;
    width: 100%;
    max-width: 320px;
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 8px;
}
.uploading_popup .uploading_popup_box {
    // overflow-y: scroll;
    // overflow-x: hidden !important;
    border-radius: 24px;
    background-color: #2F3A50;
    max-height: 100%;
}
.uploading_popup .header_txt {
    background-color: #FF4242;
    padding: 16px;
    border-radius: 24px 24px 0 0;
}
.uploading_popup .header_txt h5 {
    margin: 0;
    font-size: 16px;
}
.uploading_popup .uploading_popup__content .uploading_popup__content_close {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
    color: #fff;
    text-align: center;
    font-size: 28px;
    line-height: 26px;
    cursor: pointer;
    border-radius: 100%;
    display: inline-block;
}
.uploading_popup .popup_content_wrap {
    padding: 32px;
}
.uploading_popup .progressBar {
    margin: 24px 0 8px;
}
.uploading_popup .popup_content_wrap span {
    color: #7685A3;
    font-size: 12px;
}


//Alert Popup 
.alert_popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999999;
    top: 0;
    left: 0;
}
.alert_popup .alert_popup__content {
    position: absolute;
    width: 100%;
    max-width: 320px;
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 8px;
}
.alert_popup .alert_popup_box {
    // overflow-y: scroll;
    // overflow-x: hidden !important;
    border-radius: 16px;
    max-height: 100%;
    background-color: #FF4242;
}
.alert_popup .alert_popup__content .alert_popup__content_close {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
    color: #fff;
    text-align: center;
    font-size: 28px;
    line-height: 26px;
    cursor: pointer;
    border-radius: 100%;
    display: inline-block;
}
.alert_popup .alert_content_box {
    padding-top: 24px;
}
.alert_content_box span {
    color: #fff;
    font-size: 14px;
}
.alert_content_box h4 {
    color: #fff;
    font-size: 24px;
    margin: 12px 0;
}
.alert_content_box a.alert_btn {
    color: #fff;
    font-size: 12px;
    padding: 8px;
    background-color: #1A253B;
    display: block;
    border-radius: 24px;
}
.alert_popup .alert_popup_box {
    padding: 32px;
}


.shoutout_header_logo {
    padding: 30px 0;
    width: 100%;
    text-align: center;
    .shoutout_logo_light {
        display: none;
    }
}
.shoutout_content_section {
    &.uploading_process {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
    }
    .back_btn  {
        position: absolute;
        top: 2px;
        left: 64px; 
        z-index: 999;
        .btn {
            border: none !important;
            background-color: transparent !important;
            box-shadow: none !important;
            &:focus {
                box-shadow: none !important;
                border: none !important;
                background-color: transparent !important;  
            }
            &:active {
                box-shadow: none !important;
                border: none !important;
                background-color: transparent !important;   
            }
        }
    }
}

.shoutout_content {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    .shoutout_video_dispaly {
        width: 50%;
        height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        background-color: #000000;
        overflow: hidden;
        z-index: 1;
        align-items: center;
        justify-content: center;
    }
    .shoutout_content_section {
        width: 100%;
        height: 100vh;
        position: relative;
    }
}
.shoutout_content_box {
    text-align: center;
    max-width: 500px;
    margin: auto;
    padding: 0 30px;
}
.shoutout_copyright {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    text-align: center;
    background-color: hsl(231.43deg 6.42% 57.25% / 20%);
    padding: 6px 0;
    color: #fff;
    font-size: 12px;
    z-index: 9;
    > a {
        position: relative;
        top: 2px;
    }
}
.shoutout_two_column {
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;

    .shoutout_content_section {
        width: 50%;
    }
}   
.shoutout_content_section {
    &:not(.shoutout_two_column .shoutout_content_section ) {
        height: 100vh;
    }
}

@media screen and (max-width: 1800px) {
    .shoutout_content_section {
        .shoutout_content_wrap {
            height: calc(100vh - 130px);
            overflow-y: scroll;
            padding-bottom: 40px;
        }
    }
}
@media screen and (max-width: 992px) {
    // .shoutout_header_logo {
    //     padding-top: 0;
    // }
    // .shoutout_header_logo >div {
    //     height: 100%;
    // }
    // .shoutout_header_logo img {
    //     height: 100%;
    // }
    // .shoutout_welcome .shoutout_header_logo img {
    //     height: auto;
    // }
    .shoutout_header_logo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: 70px;
        position: fixed;
        top: 0;
        z-index: 2;
        background-color: #1A253B;
    }
    
    .shoutout_content_section {
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
        
        .shoutout_content_wrap {
            width: 100%;
            height: calc(100vh - 40px);
            overflow-y: scroll;
            display: flex;
            align-items: start;
        }
        
    }
    .shoutout_content_box {
        // height: 100% !important;
        position: static !important;
        width: 100%;
        max-width: 100%;
        padding: 80px 24px 24px;
    }
    .shoutout_content {
        .shoutout_video_dispaly {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
        }
        .shoutout_video_bg_action {
            display: none;
        }
    }
    .shoutout_header_logo {
        padding-left: 0;
        width: 100%;
        .shoutout_logo_dark {
            display: none;
        }
        .shoutout_logo_light {
            display: block;
        }
    }
    .shoutout_copyright {
        padding: 6px 0;
    }
    .shoutout_two_column {
        .shoutout_content_section {
            width: 100%;
        }
        .shoutout_video_dispaly {
            display: none;  
        }
    }
    .shoutout_content_section {
        .back_btn {
            top: 12px;
            position: fixed;
            z-index: 9;
            left: 16px;
        }
    }
}

@media screen and (max-width: 680px) {
    body .canvas-container {
        width: 320px !important;
        height: 320px !important;
        
        canvas  {
            width: 320px !important;
            height: 320px !important;
        }
    }
    .shoutout_content_section {
        .back_btn {
            .btn {
                padding: 0;
            }
        }
    }
    .welcome-section .shoutout_content_wrap {
        align-items: flex-end !important;
    }
    .welcome-section .shoutout_content_wrap .shoutout_content_box {
        padding: 16px 24px 124px !important; 
    }
    .welcome-section .shoutout_copyright {
        background-color: hsla(231.43, 6.42%, 57.25%, 0.2);
    }
    .shoutout_copyright {
        background-color: #21232B;
    }
    
}

@media screen and (max-width: 480px) {
    .shoutout_content_box {
        padding-bottom: 0;
    }
    .shoutout_content_section {
        .shoutout_content_wrap {
            height: calc(100vh - 90px);
        }
    }
    .shoutout_content_section.calender-section .shoutout_content_wrap {
        height: calc(100vh - 0px);
    }
    .shoutout_content_box {
        height: auto !important;
        margin: 0;
    }
}