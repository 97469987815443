.question_box {
    padding-top: 48px;
    .question_listing {
        display: flex;
        text-align: left;
        ul {
            background-color: rgb(47 58 80 / 40%);
            padding: 20px;
            border-radius: 8px;
            margin: 16px 0 24px;
            width: 100%;
            li {
                padding: 16px 0;
                border-bottom: 1px solid rgb(255 255 255 / 20%);
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                    border: none;
                }
            }
        }
        span {
            margin-right: 16px;
            color: rgb(255 255 255 / 40%);
        }
        p {
            margin: 0;
        }
    }
}

@media screen and (max-width: 680px) {
    .question_box {
        padding-top: 16px !important;
    } 
}

@media screen and (max-width: 480px) {
    .question_box  {
        .question_listing {
            ul {
                margin: 8px 0 16px;
            }
            span {
                font-size: 14px;
                margin-right: 10px;
            }
            p {
                font-size: 14px !important;
                line-height: 22px !important;
            }
        }
    }
}