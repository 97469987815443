.shoutout_video_bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
.shoutout_video_bg_action {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    padding: 30px;
    align-items: center;
    color: #fff;
    h3 {
        width: 90%;
        margin: 0;
    }
    
}
.play_btn {
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    transition: all 0.2s ease-out;
    border: 0;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
    span {
        position: absolute;
        width: 24px;
        height: 30px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        &:before {
            border-bottom: 20px solid #48a0f5;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            content: "";
            display: block;
            transform: rotate(90deg);
            position: relative;
            top: 5px;
            left: -7px;
        }
    }
    &.active {
        span {
            border-left: 7px solid #48a0f5;
            border-right: 7px solid #48a0f5;
            &:before {
                opacity: 0;
            }
        }
    }
}
.shoutout_video_player {
    position: relative;
    text-align: center;

    &.video_portrait {
        max-width: 100%;
        height: 100%;
    }
    &.video_landscape {
        margin: 0 auto;
        height: 100%;
    }
    video {
        height: 100%;
        opacity: 0.7;
    }
}

.shows_video {
    .shoutout_video_bg {
        filter: blur(10px);
        -webkit-filter: blur(10px);
    }
    .shoutout_video_player {
        display: flex;
        justify-content: center;
        align-content: center;
    }
}
@media screen and (max-width: 1200px) {
    .shoutout_video_bg_action h3 {
        width: 80%;
    }
}
@media screen and (max-width: 992px) {
    .play_btn {
        width: 60px;
        height: 60px;
    }

    .shoutout_video_player {
        video {
            object-fit: cover;
            width: 100%;
        }
    }
    

}

@media screen and (max-width: 992px) {
    .shoutout_video_preview {
        padding-top: 16px;
    }
}

// Oriented Screen
// @media screen and (max-height: 480px) {
//     .shoutout_video_bg_action h3 {
//         font-size: 1.2rem;
//     }
// }