.shoutout_content_box {
    .calender_box {
        background-color: #DFDFDF;
        padding: 24px;
        border-radius: 10px;
        text-align: center;
        h3 {
            color: #1A253B;
            position: relative;
            padding-left: 50px;
            text-align: left;
            .cal_emoji {
                font-size: 32px;
                position: absolute;
                left: 0;
                top: 10px;
                text-align: left;
            }
        }
        button {
            &.react-calendar__navigation__arrow {
                font-size: 24px;
            }
            &.react-react-calendar__navigation__label {
                font-size: 18px;
            }
        }
        .react-calendar__month-view__days__day--weekend {
            color: #757575 !important;
        }
    }
    .react-calendar {
        width: 100%;
        border: none;
        border-radius: 10px;
        margin-bottom: 24px;
        padding: 10px;
        margin-top: 16px;
        .react-calendar__tile {
            padding-top: 4px;
            padding-bottom: 4px;
            abbr {
                border-radius: 100%;
                font-size: 14px;
                border: 1px solid transparent;
                padding: 0;
                width: 44px;
                height: 44px;
                display: block;
                line-height: 44px;
            }
            &:hover {
                background-color: transparent;
                abbr {
                    border-color: #E6E6E6;
                    background-color: #F6F6F6;
                }
            }
            &.react-calendar__tile--now {
                background-color: transparent;
                abbr {
                    background-color: #fff;
                    border-color: #2BA8E0;
                }
            }
            &.react-calendar__tile--active {
                background-color: transparent;
                abbr {
                    color: #fff;
                    border-color: #2BA8E0;
                    background-color: #2BA8E0;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .shoutout_content_box .calender_box {
        max-width: 440px;
        margin: 0 auto;
    }
    .shoutout_content_box .calender_box h3 {
        font-size: 18px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 450px) {
    .shoutout_content_box .react-calendar .react-calendar__tile {
        padding: 0;
    }
    .shoutout_content_box .react-calendar .react-calendar__tile abbr {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        margin: 0 auto;
        text-align: center;
    }
    .react-calendar__navigation {
        margin-bottom: 0;
    }
    .react-calendar__navigation button {
        min-width: 36px;
    }

}

@media only screen and (max-width: 380px) {
    // .shoutout_content_box .react-calendar .react-calendar__tile {
    //     min-height: 34px;
    // }

}