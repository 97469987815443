@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,900;1,400;1,600;1,900&display=swap');
*, *::before, *::after {
    box-sizing: border-box;
}
html {
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
}
body {
	color: #000000;
	background-color: #fff;
    font-family: 'Montserrat', sans-serif;
    padding: 0px !important;
    margin: 0px !important;
    font-size: .9rem;
    text-rendering: optimizeLegibility;
    background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}
.btn:hover, .btn:focus
{
	text-decoration: none;
}
img {
	max-width: 100%;
}
ul{
	padding: 0;
	margin: 0;
	li {
		list-style:none;
	}
}
a,a:hover,a:focus {
	text-decoration:none;
	outline:none;
}
::selection {
	background:#3498db;
	color:#fff;
}
::-moz-selection {
	background:#3498db;
	color:#fff;
}
p {
    margin: 0 0 1rem 0;
}
h1, .h1, h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 0.5rem;
}

h1, .h1 {
	font-size: 48px;
	line-height: 56px;
}

h2, .h2 {
	font-size: 26px;
	line-height: 32px;
}

h3, .h3 {
	font-size: 20px;
	line-height: 28px;
}
h6, .h6 {
	font-size: 14px;
	line-height: 20px;
}

p {
	font-size: 14px;
	line-height: 20px;
}

.btn {
	// border-radius: 50px;
    min-height: 48px;
    line-height: 48px;
    padding-top: 0;
    padding-bottom: 0;
	border-radius: 36px;
	font-size: 16px;
    font-weight: 600;
}
.form-control {
    font-family: 'Montserrat', sans-serif;
}
.radio {
	margin: 0;
	padding: 0;
	label{
		line-height: .7;
		p{
			color: #767676;
			line-height: initial;
			margin-left: 27px;
		}
	}
	input[type="radio"]{
		display: none;
	}
	input[type="radio"] + span{
		font-size: 13px;
		position: relative;
    	padding-left: 25px;
    	line-height: 1.3;
	}
	input[type="radio"] + span::before{
		content: "";
		display: inline-block;
		width: 19px;
		height: 19px;
		margin: -3px 7px 0 0;
		vertical-align: middle;
		cursor: pointer;
		border-radius: 50%;
		background-color: #fff;
		border: 1px solid #ccc;
		position: absolute;
    	left: 0;
    	top: 1px;
	}
	input[type="radio"]:checked+span::before{
		background-color: #fff;
		border: 5px solid #69f;
	}
	input[type="radio"] + span strong{
    	display: inline-block;
    	vertical-align: top;
    	font-weight: normal;
	}

}
.checkbox {
	margin: 0;
	padding: 0;
	input[type=checkbox] {
		display:none !important;
	}
	input[type=checkbox] + span {
		font-weight: normal;
		cursor: pointer;
		margin-bottom: 0;
		padding-left: 0;
		line-height: 1;
		position: relative;
		padding-left: 24px;
	    font-size: 13px;
		display: block;
		line-height: 18px;
	}
	input[type=checkbox] + span:before {
		content: "";
		display: inline-block;
		width: 17px;
		height: 17px;
		vertical-align: middle;
		margin-right: 8px;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 2px;
		margin-top: 0;
    	position: absolute;
    	left: 0;
    	top: 0;
    	line-height: 15px;
	}
	input[type=checkbox]:checked + span:before {
		content:"\2714";
		color:white;
		background-color: #6699ff; 
		border: 1px solid #6699ff;
		text-align:center;
	}
	span {
		a {
			color: #fff;
			font-weight: 600;
			text-decoration: underline;
		}
	}
}


.btn-load {
    position: relative;
    opacity: 0.8;
    color: transparent !important;
    text-shadow: none !important;
}
.btn-load:hover,
.btn-load:active,
.btn-load:focus {
    cursor: default;
    color: transparent;
    outline: none !important;
    box-shadow: none;
}
.btn-load:before {
    content: "";
    display: inline-block;
    position: absolute;
    background: transparent;
    border: 1px solid $white;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    width: 24px;
    height: 24px;
    -webkit-animation: ld 1s ease-in-out infinite;
    -moz-animation: ld 1s ease-in-out infinite;
    -o-animation: ld 1s ease-in-out infinite;
    animation: ld 1s ease-in-out infinite;
}
.btn-default.btn-load:before {
    border-left-color: #333333;
    border-right-color: #333333;
}
.btn-lg.btn-load:before {
    margin-top: -16px;
    margin-left: -16px;
    width: 32px;
    height: 32px;
}
.btn-sm.btn-load:before {
    margin-top: -9px;
    margin-left: -9px;
    width: 18px;
    height: 18px;
}
.btn-xs.btn-load:before {
    margin-top: -7px;
    margin-left: -7px;
    width: 14px;
    height: 14px;
}
.d-none {
	display: none !important;
}
.d-flex {
	display: flex !important;
}
.d-block {
	display: block !important;
}
@-webkit-keyframes ld {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(1.1);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}
@-moz-keyframes ld {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(1.1);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}
@-o-keyframes ld {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(1.1);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}
@keyframes ld {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(1.1);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}
@media only screen and (max-width:480px) {
	body {
		background-position: center;
	}
}

@media screen and (max-width: 680px) {
	h1, .h1 {
		font-size: 1.5rem !important;
	}
	.form-group {
	    margin-bottom: 0.8rem  !important;
	}
}
// Oriented Screen
// @media screen and (max-height: 480px) {
// 	h1, .h1 {
// 		font-size: 1.2rem !important;
// 	}
	
// 	.btn-lg {
// 		padding: 0.5rem 4rem;
// 	}
	
// }

@media screen and (max-width: 680px) {
	h2, .h2 {
		font-size: 20px;
		line-height: 26px;
	}
}

@media only screen and (max-width: 480px) {
	h1, .h1 {
		font-size: 24px;
		line-height: 30px;
	}
	h2, .h2 {
		font-size: 18px !important;
		line-height: 24px;
	}
	h3, .h3 {
		font-size: 16px;
		line-height: 22px;
	}
	h6, .h6 {
		font-size: 14px;
		line-height: 20px;
	}
	p {
		font-size: 12px;
		line-height: 18px;
	}
}